.main {
    background-color: #F5F5F5;
    min-height: 87vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.green-text, .red-text {
    margin: 2px 0;
    padding: 0 15px;
    border-radius: 10px;
}

.green-text p, .red-text p {
    margin: 5px 0;
}

.green-text {
    background-color: #E8F5E9;
    border: #A5D6A7 solid 2px;
    color: #4CAF50;
}

.red-text {
    background-color: #FFEBEE;
    border: #EF9A9A solid 2px;
    color: #F44336;
}